import { Box, ChakraProps } from '@chakra-ui/react'
import { FC } from 'react'
import { useClientSetting } from 'src/clientSetting/Provider'
import LatourLogo from './latour.svg'
import MomoLogo from './momo.svg'

export const Logo: FC<{} & ChakraProps> = ({ ...props }) => {
  const client = useClientSetting()
  const logo = client.id === 'momo' ? <MomoLogo /> : <LatourLogo />

  return <Box {...props}>{logo}</Box>
}
